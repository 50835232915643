import { useEffect, useState } from "react";
import { Column, ColumnCenter } from "../base/Layout/columns";
import { addToastError, awaitForSeconds } from "../base/Components/Toasts";
import FilledButton from "../base/Buttons/FilledButton";
import { RowBetween } from "../base/Layout/rows";
import { Transition } from "@headlessui/react";
import OutlinedButton from "../base/Buttons/OutlinedButton";
import { sendMessageToDiscord } from "../helpers/DiscordHelper";

export default function Lola({}) {
  const [step, setStep] = useState(null);
  const [isReading, setIsReading] = useState(true);
  const [clicksOnNon, setClicksOnNon] = useState(false);
  const [clickOnSi, setClicksOnSi] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#FF950A";
  }, []);

  useEffect(() => {
    if (step != null && !clickOnSi && !clicksOnNon) {
      readText(step);
      return () => {
        if (document.getElementById("toread")) document.getElementById("toread").innerText = "";
        if (document.getElementById("readed")) document.getElementById("readed").innerText = "";
      };
    }
  }, [step]);

  async function readText(i) {
    if (clickOnSi || clicksOnNon > 3) return;
    setIsReading(true);
    let text = texts[i];
    text = typeof text === "string" ? text : text.text;
    sendMessageToDiscord(text);
    await readParagraph(text, i);
  }

  async function readParagraph(text) {
    if (clickOnSi) return;
    const toread = document.getElementById("toread");
    const readed = document.getElementById("readed");
    toread.innerText = text;
    await awaitForSeconds(0.7);
    while (toread.innerText.length > 0) {
      let char = text.substring(0, 1);
      if (char === " ") {
        char = text.substring(0, 2);
        text = text.substring(2);
        await awaitForSeconds(0.05);
      } else {
        text = text.substring(1);
      }
      readed.innerText += char;
      toread.innerText = text;
      await awaitForSeconds(char === "." || char === ":" ? 0.6 : char === "," ? 0.6 : char === "?" ? 0.35 : 0.04);
    }

    setIsReading(false);
  }

  return (
    <ColumnCenter className="max-w-sm lg:max-w-md w-full h-screen overflow-auto justify-center mx-auto p-5">
      <Transition
        show={clicksOnNon}
        appear
        className={"h-48"}
        enter="transition-opacity delay-500 duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <p className="text-xl w-full text-center">
          Maintenant, je te laisse tranquille.
          <br />
          <br />
          Merci encore pour ce que on as partagé et je te souhaite du succès dans toutes tes aventures.
        </p>
      </Transition>
      <Transition
        show={clickOnSi}
        appear
        className={"h-48 flex flex-col items-center space-y-4"}
        enter="transition-opacity delay-500 duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <p className="text-xl w-full text-center">Yeessss !!</p>
        <p className="text-2xl">🥳 🥳 🥳 🥳</p>
        <p className="text-xl w-full text-center">Viens m'ouvrir la porte stp...</p>
      </Transition>
      {clicksOnNon === false && !clickOnSi && (
        <>
          <h1 className="mb-5">Lola</h1>

          <Transition
            show={step == null}
            appear
            className="flex flex-col items-center"
            enter="transition-all delay-500 duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <p className="mb-10 text-lg text-center">
              <br />
              En tant que développeur, je me permets de t'écrire une page internet à la place d’une lettre postale.
              <br />
              <br />
              Avant de commencer il faut saisir le code pour verifier que c'est toi.
            </p>
            <div className="mb-4 w-full px-5 py-3">
              <p className="font-semibold">Code à 6 chiffres</p>
              <p style={{ backgroundColor: "#FF950A" }} className="px-2 py-1 text-xs italic">
                Piste : il commence par 24
              </p>
              <div className="flex p-2 justify-between">
                <input
                  id="code_0"
                  onBeforeInput={(e) => {
                    if (e.target.value.length == 1) e.preventDefault();
                  }}
                  onInput={(e) => document.getElementById("code_1").focus()}
                  type="number"
                  maxLength={1}
                  autoComplete="off"
                  className="bg-transparent w-10 lg:w-14 border-text-color placeholder:text-text-light border px-2 text-lg font-bold text-center py-4 rounded-lg focus:outline-0 focus:ring-1"
                />
                <input
                  id="code_1"
                  onInput={(e) => document.getElementById("code_2").focus()}
                  type="number"
                  maxLength={1}
                  autoComplete="off"
                  className="bg-transparent w-10 lg:w-14 border-text-color placeholder:text-text-light border px-2 text-lg font-bold text-center py-4 rounded-lg focus:outline-0 focus:ring-1"
                />
                <input
                  id="code_2"
                  onInput={(e) => document.getElementById("code_3").focus()}
                  type="number"
                  maxLength={1}
                  autoComplete="off"
                  className="bg-transparent w-10 lg:w-14 ml-3 border-text-color placeholder:text-text-light border px-2 text-lg font-bold text-center py-4 rounded-lg focus:outline-0 focus:ring-1"
                />
                <input
                  id="code_3"
                  onInput={(e) => document.getElementById("code_4").focus()}
                  type="number"
                  maxLength={1}
                  autoComplete="off"
                  className="bg-transparent w-10 lg:w-14 mr-3 border-text-color placeholder:text-text-light border px-2 text-lg font-bold text-center py-4 rounded-lg focus:outline-0 focus:ring-1"
                />
                <input
                  id="code_4"
                  onInput={(e) => document.getElementById("code_5").focus()}
                  type="number"
                  maxLength={1}
                  autoComplete="off"
                  className="bg-transparent w-10 lg:w-14 border-text-color placeholder:text-text-light border px-2 text-lg font-bold text-center py-4 rounded-lg focus:outline-0 focus:ring-1"
                />
                <input
                  id="code_5"
                  onInput={(e) => document.getElementById("code_5").blur()}
                  type="number"
                  maxLength={1}
                  autoComplete="off"
                  className="bg-transparent w-10 lg:w-14 border-text-color placeholder:text-text-light border px-2 text-lg font-bold text-center py-4 rounded-lg focus:outline-0 focus:ring-1"
                />
              </div>
            </div>
            <FilledButton
              id="start_btn"
              onClick={() => {
                let code = "";
                for (let index = 0; index < 6; index++) {
                  code += document.getElementById("code_" + index).value;
                }
                sendMessageToDiscord("Code saisi : " + code);
                if (code == "241105") {
                  setStep(0);
                } else {
                  addToastError("Ops ! Code incorrecte");
                  for (let index = 0; index < 6; index++) {
                    document.getElementById("code_" + index).value = "";
                  }
                  document.getElementById("code_0").focus();
                }
              }}
            >
              Commencer
            </FilledButton>
          </Transition>

          {step != null && (
            <p key={"toread_" + step} className="w-full h-fit text-center !break-keep text-xl">
              <span id="readed" className=""></span>
              <span id="toread" className="opacity-25"></span>
            </p>
          )}
          {typeof texts[step] === "object" && texts[step].type === "textarea" && (
            <textarea
              id="textarea"
              className="w-full bg-orange-200 text-orange-900 border-orange-500 h-32 rounded p-4 my-10 "
              placeholder="Ecrire ici..."
            />
          )}
          {typeof texts[step] === "object" && texts[step].type === "checklist" && (
            <ul className="w-full p-3 space-y-2">
              {texts[step].options.map((option) => (
                <li className="flex space-x-2 w-full">
                  <input className="checkbox h-5 w-5" type="checkbox" />
                  <p>{option}</p>
                </li>
              ))}
            </ul>
          )}
          <hr className="w-3/4 my-10 border-orange-500" />

          {typeof texts[step] === "object" && texts[step].type === "buttons" && (
            <div className=" h-48">
              <Transition
                show={isReading == false}
                className="w-full"
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <Column className="space-y-4 items-center">
                  <FilledButton
                    onClick={() => {
                      sendMessageToDiscord(" -- Click sur 'Oui, je veux bien !'");
                      setClicksOnSi(true);
                      localStorage.setItem("clickOnSi", true);
                    }}
                  >
                    Oui, je veux bien
                  </FilledButton>
                  <OutlinedButton
                    onClick={() => {
                      sendMessageToDiscord(" -- Click sur 'Non, merci'");
                      localStorage.setItem("clickOnNon", true);
                      setClicksOnNon(true);
                    }}
                  >
                    Non, merci
                  </OutlinedButton>
                </Column>
                <div className="h-24"></div>
                <OutlinedButton
                  className="!border-0"
                  hasIcon
                  onClick={() => {
                    setStep(null);
                    setIsReading(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                  <p>Recommencer</p>
                </OutlinedButton>
              </Transition>
            </div>
          )}
          {step != texts.length - 1 && (
            <div className="h-24 w-full">
              <Transition
                show={isReading == false}
                className="w-full"
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <RowBetween>
                  <OutlinedButton onClick={() => setStep(step > 0 ? step - 1 : 0)}>précédent</OutlinedButton>
                  <FilledButton
                    onClick={() => {
                      if (typeof texts[step] == "object") {
                        if (texts[step].type == "checklist") {
                          let selected = document.getElementsByClassName("checkbox");
                          let selectedText = "";
                          for (let index = 0; index < selected.length; index++) {
                            const element = selected[index];
                            if (element.checked === true) {
                              selectedText += texts[step].options[index] + ", ";
                            }
                          }
                          if (selectedText.length > 0)
                            selectedText = selectedText.substring(0, selectedText.length - 2);
                          sendMessageToDiscord("###### Options selected : " + selectedText);
                        } else if (texts[step].type == "textarea") {
                          let text = document.getElementById("textarea")?.value;
                          sendMessageToDiscord("###### Choses bonnes : " + text);
                        }
                      }
                      setStep(step + 1);
                      setIsReading(true);
                    }}
                  >
                    suivant
                  </FilledButton>
                </RowBetween>
              </Transition>
            </div>
          )}
        </>
      )}
    </ColumnCenter>
  );
}

const texts = [
  "Avant de commencer, je ne voulais pas laisser passer l’opportunité de te dire à nouveau que tu me plais et que j’ai bien aimé le temps qu’on est passé ensemble.",
  "le fait de m’abstenir à t’écrire ces derniers jours, il les à transforme dans des journées interminables. Le nombre de fois que j’étais sur le point de t’écrire c’est inexplicable.",
  "Mais je trouvais qu’il fallait bien poser un peu la tête pour mieux réfléchir. Surtout de ma part, je voulais savoir si ce sentiment de que t’es la bonne il était toujours là.",
  "Je m’ai permis de prendre un peu de recul aussi, et oui, je suis conscient que j'aurais du te dire ce que je resentais quand je te regarde dans les yeux, quand on se faissais des calins, ou quand on se prennais les mains au cafe populaire.",
  "Je suis quelqu'un de respectueux, qui peut accepter que je suis pas ce que tu cherches et de te laisser partir tranquille...",
  "Mais il faut que tu sache aussi que je suis aussi quelqu'un qui se bat pour ce qu'il aime, et ce pour ça que je veux pas te laisser partir comme ça simplement.",
  "Je peux comprendre que je suis pas à le hauteur de tes attentes en ce qui concerne le romanticisme. Et si bien j’espère gagner quelque points avec cela, c’est n’est que le début de ce que j’ai pour offrir.",
  "J’aimerais bien partir faire de ski avec toi...",
  "J'aimerais bien jouer de courses dans la piscine, gouter ta tartiflette, faire la fête ou aller au cinema ensemble...",
  "J'aimerais bien voyager, te montrer Paris, te faire decouvrir l'Argentine, cuisiner ensemble ou même faire la siesta tout un dimanche...",
  "J’aimerais bien qu’on continue à se connaître...",
  "Ce qui m'embête le plus, c’est que t’as decide de partir avant que je ne puisse même pas te montrer qui je suis vraiment.",
  "Je me considere quelqu'un de romantique, mais oui... je te previens, ça ne sera jamais comme dans les filmes, car on est de personnes bien réelles. Avec des défauts et des inconvénients...",
  {
    text: "Je t’invite à me dire quelles sont les défauts que t’as trouvé sur moi",
    type: "checklist",
    options: [
      "Des défauts avec la communication",
      "Des défauts pour le romantisme",
      "Des défauts pour faire l’amour",
      "Des défauts pour écrire le français...",
      "Des défauts pour être attentif",
      "Des défauts pour être patient",
      "Des défauts pour être honnête",
    ],
  },
  {
    text: "Je prends bien note... et je t'invite à me dire aussi ce que t'as trouvé intéressant sur moi :",
    type: "textarea",
  },
  "Sache que je trouve aussi des défauts sur ta personne, mais ce sont des défauts que ne m’empêchent pas de t’aimer.",
  "Sache aussi que si tu m’acceptes à nouveau j’essaierai de les travailler sur toi pour t’aider à t’améliorer, ainsi comme j’attends l’inverse de toi pour continuer à grandir.",
  "et je ne parle pas de grandir physiquement, non, je trouve que je suis déjà assez haut...",
  "Voilà la petite blague 😉",
  "Je reviens au sujet...",
  "Sache que t’es pas obligé de me donner une seconde chance, mais que si tu le fais j’aimerais un peu de compromis de ta part",
  "Je ne veux pas que d’un jour à l’autre tu te fâches avec moi et que tu me répond et me parles froidement.",
  "Tu le sais, que la plupart des garçons on est de cons et qu’on n'arrive pas à comprendre qu’est qu’il vous arrive.",
  "C'est pour ça que j’ai besoin que, si quelque chose ne vas pas, que tu le communiques pour m’aider à comprendre et essayer de le revoir de ma part",
  "Je me répète, j’ai pas mal de choses à te montrer de ma personne. J’ai trouvé en toi une fille super intéressant, aligne à ce que je cherche et à laquelle j’aimerais continuer à découvrir.",
  'Bah, je ne sais même pas pourquoi je dis "t’es alligne à ce que je cherche", non je reformule... tu es ce que je recherche',
  "En fin, je sais maintenant que j’ai envie de m’améliorer, oui j'ai le désir... la seule chose qui me manque maintenant c’est toi...",
  {
    text: "Est-ce que tu voudrais continuer à me connaître ?",
    type: "buttons",
  },
];
